
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_demandDIS.fund_disbursement') }}</h4>
            </template>
              <template v-slot:body>
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.fiscal_year')"
                        label-for="fiscal_year_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalList"
                            id="fiscal_year_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                     <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.fund_disbursement_list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                         <template v-slot:cell(allocated_amount)="data">
                                            {{  $n(data.item.allocated_amount, { useGrouping: false }) }}
                                        </template>
                                        <!-- <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('dae_grant_allocation_distribution.allocation_pending')}}</span>
                                          <span class="badge badge-success" v-if="data.item.status == 2">{{$t('dae_grant_allocation_distribution.allocated')}}</span>
                                        </template> -->
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.status == 3" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_view" v-if="data.item.status == 4" title="Details" v-b-modal.modal-2 @click="edit(data.item)"><i class="fas fa-eye"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-2" size="lg" :title="$t('bsri_demandDIS.fund_disbursement') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyFundDisburseList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0
      },
      rows: [],
      item: ''
    }
  },
  mounted () {
  },
  computed: {
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalyearData.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
        })
    },
    formTitle () {
      return this.$t('bsri_demandDIS.fund_disbursement') + ' ' + this.$t('globalTrans.entry')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('dae_grant_allocation_distribution.fiscal_year'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.allocated_subsidy_amount'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'allocated_amount' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'allocated_amount' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
      loadingState: function (newVal, oldVal) {
        if (newVal) {
          this.loadData()
        }
      }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyFundDisburseList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList
      const listData = data.map(item => {
        const fiscalObject = fiscalYearList.find(agmt => agmt.value === item.fiscal_year_id)

        const fiscalyearData = {
            fiscal_year: fiscalObject !== undefined ? fiscalObject.text_en : '',
            fiscal_year_bn: fiscalObject !== undefined ? fiscalObject.text_bn : ''
          }
        return Object.assign({}, item, fiscalyearData)
      })
      return listData
    }
  }
}
</script>
